export const newsContent = () => {
  const news = {
    voices: {
      author: "by Wesley Early",
      img: "voices.webp",
      title: "49 Voices: Kokayi Nosakhere of Anchorage",
      url: "https://www.alaskapublic.org/2017/02/24/49-voices-kokayi-nosakhere-of-anchorage/",
    },
    fbi: {
      author: "",
      img: "fbi.webp",
      title: "2017 Director’s Community Leadership Award Recipient",
      url: "https://www.fbi.gov/about/community-outreach/dcla/2017/anchorage-kokayi-nosakhere",
    },
    diverse: {
      author: "by Wesley Early",
      img: "diverse.webp",
      title: "Is Anchorage America’s most diverse city? Depends on who you",
      url: "https://www.alaskapublic.org/2017/05/30/is-anchorage-americas-most-diverse-city-depends-on-who-you-ask/",
    },
    ashland: {
      author: "by Jennevieve Fong",
      img: "ashland.webp",
      title: "Ashland community kindness project gains national attention",
      url: "https://ktvl.com/news/local/ashland-community-kindness-project-gains-national-attention",
    },
  };
  return news;
};
